// Use aria-controls to toggle aria-expanded
document.querySelectorAll('[aria-controls]').forEach(btn => {
  let controled = document.getElementById(btn.getAttribute('aria-controls'))
  if(!controled) return;

  btn.addEventListener('click', e => {
    let expanded = btn.getAttribute('aria-expanded') === 'true'
    console.log('Expanded:', expanded)
    if(expanded == null || expanded == false) {
      expanded = true
      document.body.dataset.expanded = controled.id
    } else {
      expanded = false
      document.body.dataset.expanded = ''
    }
    btn.setAttribute('aria-expanded', expanded)
    controled.setAttribute('aria-hidden', !expanded)
  })
})


// document.querySelectorAll('[aria-controls]').forEach(btn => {
//   let controled = document.getElementById(btn.getAttribute('aria-controls'))
//   if(!controled) return;

//   btn.addEventListener('click', e => {
//     let expanded = controled.getAttribute('aria-expanded')
//     if(expanded == null || expanded == 'false') {
//       expanded = 'true'
//       document.body.dataset.expanded = controled.id
//     } else {
//       expanded = 'false'
//       document.body.dataset.expanded = ''
//     }
//     btn.setAttribute('aria-expanded', expanded)
//     controled.setAttribute('aria-expanded', expanded)
//   })
// })